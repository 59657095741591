<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-tabs
      show-arrows
      v-if="inApp"
      v-model="selectedAppBarTab"
      align-with-title
      style="width: calc(100vw - 80px); flex: none;"
    >
      <AppBarTab
        v-for="tab in appBarTabs"
        :key="tab.key"
        :tab="tab"
      />
    </v-tabs>
    <div
      v-if="showTemporaryNotice"
      class="orange pa-3 d-flex flex-row"
    >
      <div class="flex-column pr-3">
        <v-icon style="vertical-align: top;">mdi-alert-circle</v-icon>
      </div>
      <div class="flex-column">
        <div>
          Dear valued customer. We are are planning to replace the booking view with calendar view but not before all features have been added to the calendar view.
        </div>
        <div>
          We ask of you to familiarise yourself with our calendar view, and to give input through our <a href="mailto:salescloud@salescloud.is">support email</a> on how we can improve your user experience.
        </div>
      </div>
      <div class="flex-column flex-grow-1 text-end">
        <v-btn
          icon
          @click="showTemporaryNotice = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </div>
    <HorizontalCalendarPicker
      class="white"
      @selectedDate="getBookingsByDate"
      :disabled="isGettingBookings"
      :getting-custom-range="gettingCustomRange"
      @getByTimeRange="(e) => { getBookingsByStartAndEnd(e.startTimeInMillis, e.endTimeInMillis) }"
      @showCustomRange="showCustomRange"
      :bookings="bookings"
      :show-add="true"
      :show-send-email="true"
      @addBooking="showAddBookingDialog = true"
    />
    <v-tabs-items
      :touchless="false"
      v-model="selectedAppBarTab"
    >
      <v-tab-item
        v-for="tab in tabs"
        :value="tab.key"
        :key="tab.key"
      >
        <BookingList
          v-if="tab.key === 'list'"
          length="50"
          step="50"
          :bookings="bookings"
        />
        <BookingFullCalendar
          v-if="tab.key === 'calendar'"
          :bookings="bookings"
        />
        <BookingCalendar
          v-if="tab.key === 'daily'"
          :bookings="bookings"
        />
      </v-tab-item>
    </v-tabs-items>
    <AvailabilityDialog
      v-model="showAvailabilityDialog"
      @closed="showAvailabilityDialog = false"
      v-if="showAvailabilityDialog"
      :item="bookingItemForAvailability"
      :location="bookingLocationForAvailability"
      :channel="bookingChannelForAvailability"
      :customer="bookingCustomerForAvailability"
      @selectedSlot="selectSlot"
    />
  </v-container>
</template>

<script>
// TODO: Add location selection to calendar and list
// TODO: Add event item selection to calendar and list

import BookingList from "@/components/BookingList"
import BookingDialog from "@/components/BookingDialog"
import HorizontalCalendarPicker from "@/components/common/HorizontalCalendarPicker"
import AvailabilityDialog from "@/components/booking/AvailabilityDialog"
import BookingFullCalendar from "@/components/booking/BookingFullCalendar"
import AppBarTab from "@/components/AppBarTab"
import BookingCalendar from "@/components/booking/BookingCalendar"
import moment from 'moment'

export default {
  name: 'Bookings',
  components: {
    AvailabilityDialog,
    BookingList,
    HorizontalCalendarPicker,
    BookingFullCalendar,
    AppBarTab,
    BookingCalendar
  },
  data: () => ({
    showTemporaryNotice: null,
    showAddBookingDialog: false,
    showAvailabilityDialog: false,
    bookingTime: null,
    bookingItem: null,
    bookingItemForAvailability: null,
    bookingLocationForAvailability: null,
    bookingChannelForAvailability: null,
    bookingCustomerForAvailability: null,
    filterBookingsByBookingItem: null,
    selectedSlot: null,
    modal: false,
    processing: false,
    processingBookings: false,
    processingCustomers: false,
    gettingCustomRange: false,
    showCustomDateRange: false,
    startTimeInMillis: null,
    endTimeInMillis: null,
    scrollToTime: true,
    clickedDate: null,
    type: null,
    timeCellHeight: 70,
    currentView: 'week',
    now: new Date(),
    loaded: false,
    paymentMethodModal: false,
    order: null,
    paymentMethod: null,
    resetBooking: {
      customer: {
        address: {
          name_line: null,
        },
        phone: null,
        email: null
      },
      co: {
        address: {
          name_line: null,
        },
        phone: null,
        email: null
      }
    },
    booking: {
      customer: {
        address: {
          name_line: null,
        },
        phone: null,
        email: null
      },
      co: {
        address: {
          name_line: null,
        },
        phone: null,
        email: null
      }
    }
  }),
  methods: {
    updateTemporaryNotice(value) {
      try {
        const key = this.$store.state.organization.uuid + ':bookings:showTemporaryNotice'
        localStorage.setItem(key, value)
      } catch (e) {
        // If we can't set window local storage we settle for a local variable
      }
    },
    addBooking() {
      this.bookingItem = this.filterBookingsByBookingItem
      this.showAddBookingDialog = true
    },
    selectSlot(slot) {
      this.selectedSlot = slot
    },
    showCustomRange(e) {
      if(e === true) {
        this.bookingDatesToShow = []
      }
      this.showCustomDateRange = e
    },
    startOfDay(timestamp) {
      return new Date(timestamp).setHours(0,0,0,0)
    },
    endOfDay(timestamp) {
      return new Date(timestamp).setHours(23,59,59,999)
    },
    getBookingsByDate(dateValue) {

      const date = new Date(dateValue)
      this.bookingTime = date.getTime()
      const dateMoment = moment(date.getTime())
      this.startTimeInMillis = dateMoment.startOf('day').valueOf()
      this.endTimeInMillis = dateMoment.endOf('day').valueOf()

      const payload = {
        startTimeInMillis: this.startTimeInMillis,
        endTimeInMillis: this.endTimeInMillis
      }

      this.$store.dispatch('getBookingsByDate', payload).finally(() => {
        this.bookingDatesToShow = this.bookingDates
      })
    },
    getBookingsByStartAndEnd(startInMillis, endInMillis) {
      this.gettingCustomRange = true

      const payload = {
        startTimeInMillis: startInMillis,
        endTimeInMillis: endInMillis
      }

      this.$store.dispatch('getBookingsByDate', payload).finally(() => {
        this.gettingCustomRange = false
      })
    },
    exportBookings() {
      this.$store.commit('updateExportObjects', this.bookings)
    },
    addAnother() {
      this.type = 'Create'
      this.booking = this.resetBooking
      this.modal = true
    },
    bookingAborted() {
      return null
    },
    bookingSuccess() {
      this.$store.commit('updateActionSuccess', {
        message: this.$t('success'),
        subMessage: this.$t('bookingsCreatedSuccess'),
        callbackLabel: this.$t('createAnother'),
        callback: this.addAnother
      })
      this.modal = false
    },
    bookingFailed() {
      return null
    },
    doBookingPayment(order) {
      this.order = order
      this.modal = false
      this.paymentMethodModal = true
    },
    paymentMethodSelectionAborted() {
      this.paymentMethodModal = false
    },
    paymentMethodSelected(paymentMethod) {
      this.paymentMethod = paymentMethod
      this.paymentMethodModal = false
    },
    scrollToCurrentTime () {
      let q = document.querySelector('.vuecal__now-line')
      if(q) {
        document.querySelector('.vuecal__now-line').scrollIntoView({ behavior:'smooth', block: 'center' })
      }
    },
    cellClick(e) {
      if((this.currentView === 'day' || this.currentView === 'week') && !e.date) {
        //this.clickedDate = e
        this.booking = this.resetBooking
        this.type = 'Create'
        this.modal = true
      }
    },
    click() {
      this.type = 'Create'
      this.modal = true
    },
    changeView(e) {
      if(e.view === 'day' || e.view === 'week') {
        const start = e.startDate.getTime()
        const end = e.endDate.getTime()
        this.getBookingsByDate(start, end)
      }
      this.currentView = e.view
    },
    editBooking(booking) {
      if(booking) {
        this.type = 'Edit'
        this.booking = booking
        this.modal = true
      }
    },
    parseDate(date) {
      if(date && date.toString().length === 10) {
        date = date*1000
      }
      let d = new Date(date)
      return d.toISOString().substr(0,10) + ' ' + d.toTimeString().substr(0,5)
    },
  },
  computed: {
    getShowTemporaryNoticeFromLocalStorage() {
      try {
        const key = this.$store.state.organization.uuid + ':bookings:showTemporaryNotice'
        return localStorage.getItem(key)
      } catch (e) {
        return true
      }
    },
    selectedDate() {
      return this.$store.state.selectedDate
    },
    appBarTabs() {
      return this.$store.state.appBarTabs
    },
    selectedAppBarTab: {
      set: function(newValue) {
        this.$store.commit('updateSelectedAppBarTab', newValue)
      },
      get: function() {
        return this.$store.state.selectedAppBarTab
      }
    },
    inApp() {
      return this.$store.state.inApp
    },
    query: function() {
      return this.$store.state.searchQuery
    },
    instanceList() {
      return this.$store.state.paymentMethodInstances
    },
    timeSteps: function () {
      return 5
    },
    timeFrom: function() {
      return null
    },
    timeTo: function() {
      return null
    },
    events: function() {
      const bookings = this.$store.state.bookings
      if(bookings) {
        return bookings.map(booking => {
          let bCustomer = ''

          if(booking.customer && booking.customer.address && booking.customer.address.name_line) {
            bCustomer = booking.customer.address.name_line.split(' ')[0]
          }

          if(booking.status === null || booking.status === undefined || booking.status === '0') {
            return null
          }

          return {
            start: this.parseDate(booking.startsAtTime),
            end: this.parseDate(booking.endsAtTime),
            title: (bCustomer ? bCustomer + ' - ' + (booking.item ? booking.item.title : '') : ''),
            booking: booking,
            class: 'booking'
          }
        }).filter(i => i)
      } else {
        return []
      }
    },
    startOfTodayTimestamp() {
      const today = new Date()
      today.setHours(0)
      today.setMinutes(0)
      today.setSeconds(0)
      today.setMilliseconds(0)
      return today.getTime() / 1000
    },
    endOfTodayTimestamp() {
      return this.startOfTodayTimestamp + 86400
    },
    startOfTomorrowTimestamp() {
      return this.endOfTodayTimestamp
    },
    endOfTomorrowTimestamp() {
      return this.startOfTomorrowTimestamp + 86400
    },
    bookingsToday() {

      if(this.bookings === null || this.bookings === undefined) {
        return []
      }

      return this.bookings.filter(booking => {

        if(booking.startsAtTime === null || booking.startsAtTime === undefined) {
          return false
        }

        if(booking.endsAtTime === null || booking.endsAtTime === undefined) {
          return false
        }

        return booking.startsAtTime > this.startOfTodayTimestamp && booking.endsAtTime < this.endOfTodayTimestamp
      })
    },
    bookingsTomorrow() {

      if(this.bookings === null || this.bookings === undefined) {
        return []
      }

      return this.bookings.filter(booking => {

        if(booking.startsAtTime === null || booking.startsAtTime === undefined) {
          return false
        }

        if(booking.endsAtTime === null || booking.endsAtTime === undefined) {
          return false
        }

        return booking.startsAtTime > this.startOfTomorrowTimestamp && booking.endsAtTime < this.endOfTomorrowTimestamp
      })
    },
    bookings: function() {
      const bookings = this.$store.getters.getActualBookings

      if(bookings && bookings.length) {
        if(this.query !== null && this.query !== undefined && this.query.length > 0) {
          const query = this.query.reduce((accumulator, item) => accumulator + item.value, '')

          return bookings.filter(booking => {
            let returnBooking = false
            if(booking.customer) {
              if(booking.customer.address && booking.customer.address.name_line && !returnBooking) {
                returnBooking = booking.customer.address.name_line.toLowerCase().includes(query.toLowerCase())
              }

              if(booking.customer.email && !returnBooking) {
                returnBooking = booking.customer.email.toLowerCase().includes(query.toLowerCase())
              }

              if(booking.customer.phone && !returnBooking) {
                returnBooking = booking.customer.phone.includes(query.toLowerCase())
              }
            }

            if(booking.booking_id && !returnBooking) {
              returnBooking = booking.booking_id.toString().includes(query)
            }

            return returnBooking
          })
        }
        return bookings
      }
      return bookings
    },
    isGettingBookings() {
      return this.$store.state.isUpdatingBookings
    },
    allBookingsCount() {

      if(this.bookings === null || this.bookings === undefined) {
        return 0
      }

      return this.bookings.length
    },
    todayBookingsCount() {

      if(this.bookingsToday === null || this.bookingsToday === undefined) {
        return 0
      }

      return this.bookingsToday.length
    },
    tomorrowBookingsCount() {

      if(this.bookingsTomorrow === null || this.bookingsTomorrow === undefined) {
        return 0
      }

      return this.bookingsTomorrow.length
    },
    tabs() {
      const tabs = []

      if(this.inApp) {
        tabs.push({
          title: this.$t('list'),
          key: 'list',
          badge: {
            color: 'green',
            content: 0
          }
        })

        tabs.push({
          title: this.$t('calendar'),
          key: 'calendar',
          badge: {
            color: 'green',
            content: 0
          }
        })

        tabs.push({
          title: this.$t('daily'),
          key: 'daily',
          badge: {
            color: 'green',
            content: 0
          }
        })
      }
      else {
        tabs.push({
          title: this.$t('calendar'),
          key: 'calendar',
          badge: {
            color: 'green',
            content: 0
          }
        })

        tabs.push({
          title: this.$t('list'),
          key: 'list',
          badge: {
            color: 'green',
            content: 0
          }
        })

        tabs.push({
          title: this.$t('daily'),
          key: 'daily',
          badge: {
            color: 'green',
            content: 0
          }
        })

        if(this.$store.getters.userIsAdmin && false) {
          tabs.push({
            title: this.$t('newCalendar'),
            key: 'new-calendar'
          })
        }
      }

      return tabs
    },
    genericDialog() {
      return this.$store.state.genericDialog
    }
  },
  mounted() {
		this.$store.commit('updateAppBarTabs', this.tabs)
		this.$store.commit('updateSelectedAppBarTab', this.tabs[0].key)
    this.showTemporaryNotice = this.getShowTemporaryNoticeFromLocalStorage !== 'false'
	},
  created() {
    this.processing = true

    this.$store.dispatch('getCurrentSystemTime').then(() => {
      const systemTime = this.$store.getters.systemTime
      const today = new Date(systemTime)
      const start = today.setHours(0,0,0,0)
      const end = today.setHours(23,59,59,999)

      let payload = {
        startTimeInMillis: start,
        endTimeInMillis: end
      }

      const processingBookings = this.$store.dispatch('getBookingsByDate', payload)
      const processingCustomers = this.$store.dispatch('getCustomers', {})

      Promise.all([processingBookings, processingCustomers]).finally(() => {
        this.processing = false
      })
    }).finally(() => {
    })

    this.$store.commit('updateAppBarTabs', this.tabs)
    this.$store.commit('updateSelectedAppBarTab', this.tabs[0].key)
    this.$store.commit('updateAppBarExport', {
      color: 'pink',
      callback: this.exportBookings
    })

    this.$store.commit('updateAppBarFab', {
      color: this.primaryColor,
      callback: this.addBooking,
      title: this.$t('addBooking')
    })

    this.$store.dispatch('getStaff')
    this.$store.dispatch('getAvailableBookingStates')

  },
  updated() {
    this.$store.commit('updateAppBarTabs', this.tabs)
  },
  watch: {
    showTemporaryNotice(value) {
      this.updateTemporaryNotice(value)
    },
    selectedDate(date) {
      this.getBookingsByDate(date)
    },
    bookings() {
      this.$store.commit('updateAppBarTabs', this.tabs)
    },
    selectedAppBarTab(value) {
      if(value === 'list') {
        this.scrollToTime = false
        window.scrollTo(0,0)
    }
      /*
      Hvað er eiginlega í gangi hér?
      if(value === 'calendar') {
        this.$store.commit('setIsUpdatingBookings', true)
        this.$store.dispatch('getCurrentSystemTime').then(() => {
          const systemTime = this.$store.getters.systemTime
          const today = new Date(systemTime)
          const start = today.setHours(0,0,0,0)
          const end = today.setHours(23,59,59,999)

          let payload = {
            startTimeInMillis: start,
            endTimeInMillis: end
          }

          this.$store.dispatch('getBookingsByDate', payload)
        })

        this.scrollToTime = true
        this.scrollToCurrentTime()
      }
      */
    },
    genericDialog(value) {
      if(!value) {
        this.showAddBookingDialog = false
      }
    },
    showAddBookingDialog(value) {
      if(value) {
        this.$store.commit('updateGenericDialog', {
					'component': 'components/booking/AddBookingCard',
					'props': {
						'bookingItem': this.bookingItem,
						'bookingTime': this.bookingTime
					}
				})
      }
    },
    modal(value) {
      if(value) {
        this.$store.commit('updateGenericDialog', {
					'component': 'components/booking/AddBookingCard',
					'props': {
          'bookingObject': this.booking,
					'bookingItem': this.bookingItem,
					'bookingTime': this.bookingTime
					}
				})
      }
    }
  }
}
</script>
